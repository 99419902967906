

.css-6w5dea {
    border: 1px solid #ccc;
    padding: 16px;
    min-width: 250px;
    background: white;
    border-radius: 16px;
  }
  
  
  .css-vjlcbk {
    padding: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 16px;
    overflow-x: auto;
    white-space: nowrap;
    background: #F9F9FB;
  }



  .css-1ex1afd-MuiTableCell-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 0px !important;
    text-align: left;
    padding: 0px !important;
    color: rgba(0, 0, 0, 0.87);
}

