/* Main container styling */
.login-container {
  height: 100vh; /* Takes up full viewport height */
  width: 100%;   /* Takes up full width */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5; /* Light background color */
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding is included in element width and height */
}

/* Grid to handle the two sides: background and card */
.login-grid {
  height: 100%; /* Full height grid */
  width: 100%;  /* Full width grid */
  display: flex;
  flex-wrap: wrap; /* Ensures wrapping on smaller screens */
}

/* Left side: background image or color */
.login-left {
  background: linear-gradient(to right, #a8e063, #56ab2f); /* Light green to dark green gradient */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1; /* Takes up more space in larger screens */
  background-size: cover;
  background-position: center;
}

/* Right side: Login form */
.login-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.4; /* Takes up 40% of space */
  height: 100%; /* Full height */
  padding: 20px; /* Add some padding around the form */
  background-color: #fff; /* White background for contrast */
}

/* Logo styling */
.company-logo {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
}

/* Card styling */
.MuiCard-root {
  width: 100%;
  max-width: 500px; /* Increased max-width for larger screens */
  padding: 40px; /* Increase padding for a more spacious look */
  border-radius: 12px; /* Rounded corners for a modern look */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Error message styling */
.error {
  color: red;
  margin-top: 10px;
}

/* Form input styling */
.MuiTextField-root {
  margin-bottom: 20px;
}

/* .MuiButton-contained {
  background: linear-gradient(to right, #a8e063, #56ab2f);
  color: white;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  transition: background 0.3s ease;
} */
/* 
.MuiButton-contained:hover {
  background: linear-gradient(to right, #56ab2f, #a8e063); 
} */

/* Responsive media queries */

/* Medium to large screens (960px and up) */
@media (min-width: 960px) {
  .login-left {
    flex: 0.55; /* Take up 55% of the width */
  }
  .login-right {
    flex: 0.45; /* Take up 45% of the width */
  }

  .MuiCard-root {
    max-width: 650px; /* Further increased width for large screens */
    padding: 50px; /* Increase padding for better spacing */
  }
}

/* Extra large screens (1200px and up) */
@media (min-width: 1200px) {
  .login-left {
    flex: 0.6; /* Left side takes slightly more space */
  }
  .login-right {
    flex: 0.4; /* Right side takes slightly less space */
  }

  .MuiCard-root {
    max-width: 750px; /* Increased width for very large screens */
    padding: 60px; /* More padding for larger screens */
  }
}

/* Ultra-large screens (above 1600px) */
@media (min-width: 1600px) {
  .MuiCard-root {
    max-width: 850px; /* Further increase the width for ultra-large screens */
    padding: 70px; /* More padding for ultra-large screens */
  }
}

/* Small screens (below 960px) */
@media (max-width: 960px) {
  .login-left {
    flex: 0.5; /* Left side takes 50% of the width */
  }
  .login-right {
    flex: 0.5; /* Right side takes 50% of the width */
  }

  .MuiCard-root {
    max-width: 400px; /* Smaller cards for mobile devices */
    padding: 30px;
  }
}

/* Very small screens (below 768px) */
@media (max-width: 768px) {
  .login-left {
    display: none; /* Hide the left side on very small screens */
  }
  .login-right {
    flex: 1; /* Right side takes full width */
    padding: 20px;
  }

  .MuiCard-root {
    max-width: 350px; /* Further reduce width */
    padding: 25px; /* Reduced padding */
  }
}

/* Logo size adjustment for smaller screens */
@media (max-width: 600px) {
  .company-logo {
    width: 100px; /* Smaller logo for smaller screens */
    margin-bottom: 15px;
  }
}

/* Ensure inputs are responsive */
/* .MuiInputBase-root {
  font-size: 14px;
  padding: 10px;
} */


.rotating-loader {
  width: 100px;
  height: 100px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
