body, html, #root{
  height: 100%;
  margin: 0;
}

.rate-color-bg{
  background-color: rgb(179, 164, 146);
  color: white;
  font-weight: bold;
}

.colum-color{
  background-color: rgb(53, 123, 103);
  color: white;
  font-weight: bold;
}

.zone-color{
  background-color: rgb(88, 80, 78);
  color: white;
  font-weight: bold;
}

.zone-color2{
  background-color: rgb(228, 209, 185);
  color: black;
  font-weight: bold;
}

.css-evt6po-MuiDataGrid-root .MuiDataGrid-cell--textRight {
  text-align: center !important;
  justify-content: flex-end;
}

.css-ttn1xo {
  padding: 0px !important;
}

.css-yfo96e {
  padding: 0px !important;
}





