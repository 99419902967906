.sidebar {
  /* width: 250px; */
  background-color: white;
  color: black;
  transition: width 0.3s ease-in-out;
  border-radius: 0px 15px 15px 0px;
  box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}

.sidebar.closed {
  width: 60px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.sidebar ul li {
  padding: 15px 20px;
  text-align: left;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.sidebar ul li:hover {
  background-color: #f0f0f0;
}

.sidebar ul li.active {
  background-color: #32CD32 !important; /* Light green color for active item */
  color: white !important;
}

.sidebar ul li.active .MuiSvgIcon-root {
  color: white !important;
}

.sidebar button {
  margin: 20px 10px;
  padding: 10px 15px;
  background-color: deepskyblue;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.sidebar button:hover {
  background-color: rgba(0, 191, 255, 0.8);
  transform: scale(1.05);
}

.sidebar img {
  width: 80%;
  margin: 0 20px 20px 0; /* Positioned at the top left */
  display: block;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.sidebar img:hover {
  transform: scale(1.05);
}

/* .css-tkmvep {
  width: 400px !important;
  height: 100vh;
} */


.css-tkmvep {
  width: auto !important;

}


.css-10hburv-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
  font-size: larger !important;
}

