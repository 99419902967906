.topbar {
  background: linear-gradient(to right, #a8e063, #56ab2f); /* Light green to dark green gradient */
  color: white;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.avatar {
  cursor: pointer;
}

.menu-item {
  text-align: center;
}

.menu-item:hover {
  background-color: #f5f5f5; /* You may want to adjust this */
}
